import React, { useState } from 'react';
import {Button, Pane, Text} from 'evergreen-ui'

const Counter = () => {
    const [counter, setCounter] = useState(0);

    return (
        <Pane
            display="flex"
            alignItems="center"
            justifyContent="center"
            border="default"
            margin={16}
            padding={16}
            flexDirection="column"
        >
            <Button
                marginBottom={16}
                appearance={"primary"}
                onClick={() => setCounter(counter+1)}
            >
                +1
            </Button>
            <Text>{counter}</Text>
        </Pane>
    );
}

export default Counter;
